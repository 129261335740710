import { FC, RefObject, useContext, useEffect, useRef } from "react";
// eslint-disable-next-line
import { TFunction } from "i18next/typescript/t";
import FocusTrap from "focus-trap-react";

import useOnClickOutside from "../../hooks/useOnClickOutside";

import ModalContext from "../../context/modal";

import { cssClasses, pages } from "../../utils/const";

import ContactForm from "../contactForm/ContactForm";
import SuccessModal from "../successModal/SuccessModal";
import { usePathname } from "next/navigation";

interface IProps {
	t: TFunction;
}

const ContactUsModal: FC<IProps> = ({ t }): JSX.Element => {
	const { toggleContactUsModal, successModalActive, toggleSuccessModal } =
		useContext(ModalContext);

	const modalElemRef: RefObject<HTMLDivElement> = useRef(null);
	const pathname = usePathname();

	useOnClickOutside(modalElemRef, () => {
		toggleContactUsModal();
	});

	const handleCloseModal = (): void => {
		toggleContactUsModal();
	};

	useEffect(() => {
		document.body.classList.add(cssClasses.NO_SCROLL);

		return () => {
			document.body.classList.remove(cssClasses.NO_SCROLL);
		};
	}, []);

	return (
		<div className="modal">
			<FocusTrap>
				<div className="modal__box" ref={modalElemRef}>
					{successModalActive ? (
						<SuccessModal t={t} />
					) : (
						<ContactForm
							title={t("common:CONTACT_FORM.MODAL.TITLE")}
							subtitle={
								pathname === pages.PRICING
									? t("common:CONTACT_FORM.MODAL.SUBTITLE_PRICING")
									: t("common:CONTACT_FORM.MODAL.SUBTITLE")
							}
							onClose={handleCloseModal}
							t={t}
							onSubmit={() => {
								toggleSuccessModal();
								toggleContactUsModal();
							}}
						/>
					)}
				</div>
			</FocusTrap>
		</div>
	);
};

export default ContactUsModal;
